<template>
<div class="p-about">
	<Section1/>
	<Section2/>
	<!-- <Section3/> -->
	<Section4/>
</div>
</template>
<script>
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'
import Section4 from './components/Section4'
export default {
	components: { Section1, Section2, Section3, Section4 } // eslint-disable-line
}
</script>
<style lang="less" scoped>
@import "./About.less";
</style>
