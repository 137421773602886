<template>
<Wrapper min-height="836px">
	<div id="p-about-anchor-1" style="height:80px"/>
	<DecoratedTitle center class="g-fonts-24 g-letters-29">合作伙伴</DecoratedTitle>
	<div style="height:60px"/>
	<div class="g-position-r">
		<div style="overflow:hidden"/>
		<div class="p-about-b3-img">
			<img class="g-img-fit" src="@/assets/img/about/about-img2.png" alt="">
		</div>
		<div class="g-about-b3-msg g-position-a g-top-0 g-left-0">
			<div class="g-fonts-24 g-letters-29 g-marginb-30">我们的伙伴</div>
			<div class="g-fonts-24 g-letters-19 g-fonts-16 g-marginb-30" style="line-height: 44px">与我们合作，是您科技商业化世界关键的一步。我们秉承普惠理念，让金融科技更好的扎根实体经济，服务亿万民众，并可持续发展。您将获得业界最坚实的区块链技术基石，最广泛的生态合作，以及最有诚意的合作伙伴计划。欢迎加入我们，携手前进。</div>
			<Link>联系我们</Link>
		</div>
	</div>
</Wrapper>
</template>
<script>
export default {
	activated() {
		if (this.$route.query.partner) {
			document.querySelector('#p-about-anchor-1').scrollIntoView(true)
		}
	}
}
</script>

<style lang="less" scoped>
.p-about-b3-img {
	margin-left: 180px;
	margin-top: 50px;
	height: 500px;
	> img {
		box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
	}
}
.g-about-b3-msg {
	width: 400px;
	height: 500px;
	background: #005FFF;
	box-shadow: 0 2px 20px 0 rgba(0,95,255,0.20);
	border-radius: 5px;
	color: #fff;
	padding: 50px 30px;
}
</style>
