<template>
<Wrapper  >
	<div id="p-about-anchor-2" style="height:80px"/>
	<DecoratedTitle center class="g-fonts-24 g-letters-29">合作案例</DecoratedTitle>
	<div style="height:50px"/>
	<div class="p-about-b4-n">
		<a-tabs>
			<a-tab-pane v-for="item in list" :key="item.name" :tab="item.name">
				<div class="g-paddingl-30 g-paddingr-30">
					<div style="height: 60px"/>
					<a-row :gutter="110">
						<a-col :span="14">
							<div class=" g-marginb-30" >
								<img style="width: auto; height: 37px" :src="item.img" alt="">
							</div>
							<div class="g-fonts-21 g-letters-25">{{item.title}}</div>
							<div style="height: 50px"/>
							<div class="g-fonts-16 g-letters-19" style="line-height: 44px" v-html="item.text"></div>
						</a-col>
						<a-col :span="10">
							<img class="g-img-auto" :src="item.imgChart"/>
						</a-col>
					</a-row>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
	<div style="height:50px"/>
</Wrapper>
</template>
<script>
export default {
	data() {
		return {
			list: [
				{
					name: '科技输出案例',
					// img: require('@/assets/img/about/about-logo2.png'),
					imgChart: require('@/assets/img/about/about-case1.jpg'),
					title: '案例：为某大型国有银行地方分行提供聚合支付能力',
					text: `新地微融与某大型国有银行地方分行合作，基于闽政通APP定制化打造统一支付平台与用户管理平台。统一支付平台通过汇聚各级相关部门和公共服务机构线上线下办事能力，提供政务服务和公共服务统一的支付平台和服务入口。`
				},
				{
					name: '流量撮合案例',
					// img: require('@/assets/img/about/about-logo3.png'),
					imgChart: require('@/assets/img/about/about-case2.jpg'),
					title: '案例：为股份制银行提供优质流量',
					text: `新地微融作为导流方，为股份制银行提供优质流量接入服务，对接各大资产，例如教育分期场景、摩托车分期场景等。通过为行方精准匹配不同客群流量，有效助力银行数字化营销拓展。`
				},
				{
					name: '战略咨询案例',
					// img: require('@/assets/img/about/about-logo5.png'),
					imgChart: require('@/assets/img/about/about-case3.jpg'),
					title: '案例：为某农商银行提供战略咨询服务',
					text: `新地微融为某农村商业银行提供了战略架构咨询服务，深入调研农商行内部现有系统与应用架构分布、工作流程、服务效能、用户体验等现状痛点，结合银行未来三年发展规划与业务转型重点，制定不同阶段下相应的架构升级与系统建设规划方案，有效助力该农商行推进数字化进程。`
				},
				// {
				// 	name: '如东农商银行',
				// 	img: require('@/assets/img/about/about-logo4.png'),
				// 	imgChart: require('@/assets/img/about/about-case4.jpg'),
				// 	title: '案例：为如东农商银行提供信贷风险管理服务',
				// 	text: `洲联为如东农商银行定制化打造智能风控管理系统，实现信贷风险的线上化、智能化管理，助力银行高效管控风险，提升核批效率与资产质量，为行内小微金融业务的数字化转型打下重要基石。`
				// },
			]
		}
	}
}
</script>
<style lang="less" scoped>

.p-about-b4-img {
	margin-left: 180px;
	margin-top: 50px;
	height: 500px;
	> img {
		box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
	}
}
.g-about-b4-msg {
	width: 400px;
	height: 500px;
	background: #005FFF;
	box-shadow: 0 2px 20px 0 rgba(0,95,255,0.20);
	border-radius: 5px;
	color: #fff;
	padding: 50px 30px;
}
</style>
<style lang="less">
.p-about-b4-n {
	.ant-tabs-nav-scroll {
		padding: 0 30px;
	}
	.ant-tabs-tab-active {
		font-weight: bold;
	}
	.ant-tabs-ink-bar {
		background: transparent;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 20%;
			right: 20%;
			background: #005FFF;

		}
	}
}
</style>
